import { useState, useEffect } from "react";
import { ls, ss } from '../utils/store';

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    ss.setFormated('isLoaded', false);
    setIsLoaded(true);
  }, [])
  useEffect(() => {
    window.addEventListener("load", () => {
      ss.setFormated('isLoaded', true);
      ss.setFormated('winW', window.innerWidth);
    });
  }, [isLoaded]);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      ss.setFormated('isResized', false);
      if (ss.getFormated('winW') !== window.innerWidth && ss.getFormated('isLoaded') && !ss.getFormated('isResized')){
        window.location.reload();
        ss.setFormated('isResized', true);
      }
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export default useWindowSize;
